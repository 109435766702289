







































































































































































































































































































































































































import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Common from '@/mixins/Common.vue'
import axios from 'axios'
const UIMutation = namespace('ui').Mutation
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  data: () => {
    return {
      isAccountLoaded: false
    }
  },
  props: {},
  mixins: [Common],
  created () {
    //@ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Online Satoshi Circle Games with Bitcoin Cash and Bitcoin`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Satoshi Circle`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  methods: {
    initGame() {
      this.isAccountLoaded = true;
      this.initializeGameScale()
      //@ts-ignore
      this.$loadScript('/js/legacy/satoshicircle.js').then(() => {
        // connect to the javascript
        // init_satoshicircle()
      })
    }
  },
  watch: {

  },
  async beforeCreate() {
    try {
      let res = await axios.get('/api/satoshicircle/ruleset')
      if(res.data.result) {
        return res.data.result
      } else {
        throw new Error('Invalid angry banker ruleset response')
      }
    }
    catch(e) {
      this.$router.push('maintenance')
      console.log("Error trying to get the angry banker ruleset")
      return e
    }
  },
  mounted () {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init satoshicircle game when the token
    // is available
   this.initGame()
  }
})
@Component({
  components: {
    FastGameMenu
  }
})
export default class SatoshiCircle extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @AccountGetter userToken
}
